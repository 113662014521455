@import '../stylesheets/variables'


// Hide screen elements
// --------------------------------------------------------------------

nav,
footer,
.add_instance,
.form-filter,
.logo,
#action_nav,
#page_sidenav,
#page_sidenav + #page_content:after,
.column-actions
  display: none


// Print specific
// --------------------------------------------------------------------

body
  background-color: white

#page_content
  margin: 0!important
  background-color: white
  h1, h2, h3, h4, h5, h6
    page-break-after: avoid
    break-after: avoid

.column-main
  max-width: 100%
  flex: 0 0 100%

p,
ul,
ol,
dl
  orphans: 3


// Components
// ---------------------------------------------------------------------

// Wrapper for responsive tables
.table-responsive
  overflow: visible!important
  border: none
.table
  border: 1px solid $gray-lighter
thead
  display: table-header-group
tfoot
  display: table-row-group
tr
  page-break-inside: avoid

.traffic_lights
  height: 12px
  width:  12px
  border-radius: 12px


.data-item
  border-top: 1px solid lighten($gray-light, 20)
  border-right: 1px solid lighten($gray-light, 25)
  border-left: 1px solid lighten($gray-light, 25)
  .data-item-details
    padding-top: 10px
    padding-bottom: 20px
    border-bottom: 1px solid lighten($gray-light, 20)
  .table
    border: none

.avoid_page_break
  page-break-inside: avoid
.page_break_before
  page-break-before: always
